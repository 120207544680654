@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';


.ant-modal-wrap {
  overscroll-behavior: contain;
  z-index: 10000100000 !important;

  .custom-wrap-modal {
    overflow: hidden;
    max-height: var(--app-height);

    @media @viewport-mobile {
      max-height: 100dvh;
    }
  }
}

.ant-modal.custom-modal {
  padding-bottom: 0;
  max-height: var(--app-height);
  overflow-y: auto;

  @media @viewport-mobile {
    &.full-screen{
      border-radius:0 !important;
      overflow: hidden !important;
      .ant-modal-content{
        border-radius:0 !important;
      }
      .ant-modal-body{
        max-height: unset !important;
      }
    }
  }

  @media @viewport-mobile {
    max-height: 100dvh;
  }

  .ant-modal-header {
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
    });
  }

  .ant-modal-content {
    padding: 0;
    border-radius: 20px;
    border: none !important;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[modal-bg];
    });

    @media @viewport-mobile {
      border-radius: 24px 24px 0 0 !important;
    }

    .ant-modal-close {
      top: 24px;
      right: 24px;
    }

    .ant-modal-body {
      padding: 0 !important;
      font-family: 'Inter', sans-serif;
    }

    .ant-modal-header {
      border-radius: 20px 20px 0 0 !important;

      @media @viewport-mobile {
        border-radius: 24px 24px 0 0 !important;
      }
    }
  }

  @media @viewport-mobile {
    position: absolute;
    bottom: 0;
    width: 100% !important;
    max-width: 100vw;
    margin: 0;
    top: unset;

    //animation: slide-modal 0.5s linear;

    &.full{
      .ant-modal-content{
        .ant-modal-body{
          height: 100%;
          min-height: calc(var(--app-height) - 56px - 92px);
          height: var(--app-height);
        }
      }
    }

    &.small {
      animation: slide 0.35s linear;
    }

    .ant-modal-content {
      border: none;
      border-radius: @border-radius @border-radius 0 0;

      .ant-modal-close {
        top: 14px;
        right: 8px;
      }

      .ant-modal-header {
        padding: 24px 20px 0;
      }

      .ant-modal-body {
        position: relative;
        padding: 16px 20px 32px;
        max-height: calc(var(--app-height) - 56px - 92px);
        overflow: hidden;
        overflow-y: auto;

        .modal-sub-title {
          margin: 24px 0;
        }
      }

      .ant-modal-footer {
        padding: 0;
        display: block;
        margin-top: 0;
      }
    }
  }

  &.modal-competitors {
    .ant-modal-content {
      .ant-modal-header {
        margin-bottom: 0;
        padding: 24px;

        .ant-modal-title {
          > span.modal-competitors-title {
            & > span {
              position: relative;
            }

            p {
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.3px;
              margin-top: 10px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              });
            }

            .header-tag {
              position: absolute;
              top: 50%;
              right: -8px;
              transform: translate(100%, -50%);
              padding: 4px 6px;
              border-radius: 6px;
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: -0.3px;
              .with-theme({
                background: @vars[blue-xlight-to-neutral-dark];
                color: @vars[blue-depressed-to-white];
              })
            }
          }
        }
      }

      .ant-modal-body {
        padding: 22px 24px 9px;

        .competitors-card-list-header span:first-of-type {
          width: 55%;
        }

        .competitors-card-info-wrap {
          min-width: unset;
          width: 55%;
        }
      }
    }
  }

  &.modal-trial{
    .ant-modal-content {
      .ant-modal-header {
        .with-theme({
          background-color: @vars[yellow-100-to-900];
        });
        padding-bottom: 24px;

        @media @viewport-mobile {
          padding: 28px 0 27px;
        }

        .ant-modal-title {
          display: flex;
          align-items: center;
          justify-content: center;

          svg{
            @media @viewport-mobile {
              width: 88px;
              height: 88px;
            }
          }
        }
      }

      .ant-modal-body {
        @media @viewport-mobile {
          padding: 24px 20px 48px;
        }
        .modal-sub-title{
          display: block;
          text-align: center;
          margin: 0 0 16px;
        }
        p{
          text-align: center;
        }
      }
    }
  }

  &.header-modal {
    .collection-modal-text {
      padding: 0 20px;
      margin-bottom: 0;
    }

    .sales-tracker-top-page-header-dropdown {
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      padding: 24px 8px;
    }
  }
}

.custom-modal {

  @media @viewport-mobile {
    border-radius: 24px 24px 0 0;
  }

  &.portfolio-sort-modal {
    @media @viewport-mobile {

      .ant-modal-body {
        max-height: calc(100dvh - 40px) !important;
      }

      .ant-modal-close {
        top: 14px !important;
        right: 14px !important;
      }

      .search-sort-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
        padding: 16px 16px 0;

        &-inner {
          transition: all .3s ease;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          border: none !important;
          height: 40px;
          padding: 6px 12px;
          border-radius: 10px;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
            background: @vars[white-to-neutral-dark-50];
            box-shadow: @vars[box-shadow-btn-secondary];

            &:hover, &:focus {
              background: @vars[neutral-50-to-neutral-dark-100];
            }
          });

          @media @viewport-mobile {
            width: calc(100% - 86px);
            justify-content: center;
          }
        }

        &-wrapper {
          width: 215px;
          height: 284px;
          padding: 8px;
          overflow-y: scroll;
          border-radius: 12px;
          .with-theme({
            background: @vars[white-to-neutral-dark-50];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            box-shadow: @vars[box-shadow-object-large];
          });

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }
          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }
        }

        &-item {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          padding: 8px;
          cursor: pointer;
          transition: all .3s ease;
          width: 100%;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .with-theme({
            color: @vars[neutral-800-to-100];

            &:hover {
              background: @vars[neutral-100-to-neutral-dark-100];
            }
          });

          &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
          }
        }
      }

      .custom-modal-btn-wrapper {
        justify-content: center;

        .custom-modal-button {
          width: calc(50% - 6px);
        }
      }
    }
  }

  &.logout-modal {
    @media @viewport-mobile {

      .ant-modal-close {
        top: 14px !important;
        right: 14px !important;
      }

      .custom-modal-btn-wrapper {
        justify-content: center;

        button {
          width: calc(50% - 6px);
        }
      }
    }
  }

  &.change-theme-modal {

    .ant-modal-close {
      top: 14px !important;
      right: 14px !important;
    }
  }

  .theme-switcher-dropdown {
    background: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0 16px 16px !important;
    border: none !important;
    margin-top: 16px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-title {
    padding: 16px 16px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-text {
    padding: 0 16px;
    margin-top: 8px;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &-btn-wrapper {
    width: 100%;
    padding: 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  &-button {
    height: 40px;
    padding: 6px 12px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    cursor: pointer;
    transition: all .3s ease;
    border: none !important;
    .with-theme({
      color: @vars[neutral-800-to-100];
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus, &:active {
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-100];
      }
    });

    &.red {
      color: @redPrimary !important;
    }

    &.blue {
      background: @bluePrimary;
      color: @white !important;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-primary];

        &[disabled] {
          cursor: not-allowed;
          box-shadow: none !important;
          background: @vars[neutral-100-to-neutral-dark-100];
          color: @vars[neutral-300-to-neutral-dark-300] !important;
        }
      });

      &:hover, &:focus, &:active {
        background: @blueDepressed;
      }
    }

    &.no-border {
      box-shadow: none !important;
      color: @bluePrimary;
      margin-right: auto;
      background: transparent !important;

      &:hover, &:focus, &:active {
        color: @blueDepressed;
      }
    }
  }

  &.adspot-sort-order {
    .cascading-modal-menu-wrapper-inner {
      padding: 16px;

      .with-theme({
        background: @vars[white-to-neutral-dark-50];
      });

      .cascading-modal-button {
        width: 100%;
        background-color: @bluePrimary;
        border-radius: 10px;
        border: none;
        &:hover {
          background-color: @blueDepressed;
        }
      }
    }

    .cascading-modal-menu-inner-header {
      font-size: 16px;
      .with-theme({
        color: @vars[neutral-800-to-neutral-100];
      });
      svg {
        transform: rotate(90deg);
        path {
          .with-theme({
            stroke: @vars[neutral-800-to-neutral-100];
          });
        }
      }
    }

    .cascading-modal-menu-inner {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 12px 0;
      .cascading-modal-menu-item-inner {
        padding: 8px;

        span {
          display: flex;
          align-items: center;
          font-size: 14px;
          gap: 8px;
          text-transform: capitalize;

          .with-theme({
            color: @vars[neutral-800-to-neutral-100];
          });
        }
        &-active {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.adspot-product-import {
    .product-import-modal {
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
      });

      &-header {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 8px;
      }

      &-title {
        font-size: 16px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      &-description {
        font-size: 14px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      .filter-preset-action-dropdown {
        box-shadow: none;
        border: none;
        padding: 0px 0px 16px 16px;
        background: transparent;

        .action-dropdown_item {
          background: transparent;
          padding: 8px;
        }
      }
    }
  }
}

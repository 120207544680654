@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.adLibrary-item {
  padding: 12px 0;
  border-radius: 16px;
  position: relative;
  .with-theme({
    background: @vars[white-to-neutral-dark];
    box-shadow: @vars[box-shadow-btn-secondary];
  });

  &.adLibrary-item-modal {
    width: 350px !important;

    .fadspot-page-item-wrapper.addLibrary {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1400px) {
    width: calc((100% - 16px) / 2);
  }

  @media screen and (min-width: 1401px) and (max-width: 1900px) {
    width: calc((100% - 32px) / 3);
  }

  @media screen and (min-width: 1901px) and (max-width: 2500px) {
    width: calc((100% - 48px) / 4);
  }

  @media screen and (min-width: 2501px) {
    width: calc((100% - 64px) / 5);
  }

  @media @viewport-mobile {
    width: 100%;
  }

  &-header {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-info-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-logo {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: contain;

      &-wrapper {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }
    }

    &-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;

      .adLibrary-item-header-title {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      .adLibrary-item-header-status {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: @greenPrimary;

        &-circle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: @greenPrimary;
          &.red{
            background: @redPrimary;
          }
        }

        &.red{
          color: @redPrimary;
        }
      }
    }

    &-action {
      cursor: pointer;

      svg path {
        .with-theme({
          fill: @vars[neutral-500-to-neutral-dark-500];
        })
      }

      &-dropdown {
        width: 235px;
        padding: 8px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .with-theme({
          box-shadow: @vars[box-shadow-object-large];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[white-to-neutral-dark];
        });

        &-option {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 8px;
          padding: 0 8px;
          height: 36px;
          transition: all .3s ease;
          cursor: pointer;
          border-radius: 8px;

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100];
            })
          }

          & > svg {
            width: 16px;
            height: 16px;
          }

          & > span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            })
          }
        }
      }
    }
  }

  &-stats {
    padding: 0 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    align-items: center;

    .adLibrary-item-stat {
      //cursor: pointer;
      //transition: all .3s ease;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      //text-decoration-line: underline;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });

      //&:hover {
      //  color: @bluePrimary !important;
      //}
    }
  }

  &-description {
    margin-bottom: 12px;
    padding: 0 16px;
    width: 99%;
    text-align: left;
    overflow-y: scroll;
    height: 32px;
    position: relative;

    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    .with-theme({
      color: @vars[neutral-500-to-neutral-dark-500];
    })
  }


  &-target-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 40px;
    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    .adLibrary-item-target {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;

      &-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];
        });
      }

      &-value {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }
      &-value-with-flag {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
        svg{
          width: 24px;
          margin-right: 2px;
        }
      }
    }
  }
  .country-flags{
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .extra-flags{
    background: @bluePrimary;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.3px;
    text-align: center;
    color: @white;
    padding: 4px;
    border-radius: 6px;
  }

  .badge {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 6px;
    margin-left:4px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    text-wrap: nowrap;
    @media @viewport-mobile {
      width: 100%;
      align-items: center;
      text-align: center;
      height: 32px;
      span{
        width: 100%;
      }
    }
    .with-theme({
      color: @vars[blue-depressed-to-neutral-dark-500];
      background: @vars[blue-light-to-neutral-dark-200];

      &:hover {
        background: @vars[blue-to-neutral-dark-300];
        color: @vars[blue-xlight-to-neutral-dark-500];

        .icon-btn.ant-tooltip-open,.icon-btn{
          svg{
            path{
              fill: #C5CCE3 !important;
            }
          }
        }
      }
    })
  }

  &-insights {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 16px;

    span {
      cursor: pointer;
      transition: all .3s ease;
      color: @bluePrimary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
    }

    &:hover span {
      color: @blueDepressed;
    }
  }

  &-image-wrapper {
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      .with-theme({
        background: @vars[bottom-gradient-bg];
      });
    }

    .adLibrary-item-image {
      height: 100%;
      width: 100%;
      z-index: 0;
      object-fit: contain;
    }
  }

  &-cta-wrapper {
    padding: 12px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-100];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    .adLibrary-item-cta-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .adLibrary-item-cta-title {
        transition: all .3s ease;
        cursor: pointer;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: @bluePrimary;

        &:hover {
          color: @blueDepressed;
        }
      }

      .adLibrary-item-cta-text {
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        })
      }
    }

    .adLibrary-item-cta-btn {
      white-space: nowrap;
      cursor: pointer;
      transition: all .3s ease;
      display: flex;
      height: 36px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      border-radius: 8px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });
    }
  }

  &-info-wrapper {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 4px;
    margin-top: 12px;

    .adLibrary-item-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        width: 50%;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      &-value {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &-gray {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });
        }
      }
      &-value-date {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        display: flex;
        align-items: flex-start;
        text-wrap: nowrap;

        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &-gray {
          width: 100%;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          text-wrap: nowrap;
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });
        }
      }
    }
  }
}

.addLibrary-page-item-action-title{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: @bluePrimary;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
    text-wrap: nowrap;

    @media @viewport-mobile {
      font-size: 12px;
      line-height: 14px;
    }
}

.addLibrary-page-item-action-sub-title{
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  overflow-x: auto;
  max-width: 210px;
  max-height: 30px;
}

.addLibrary-page-item-action-description{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  text-wrap: nowrap;
  font-size: 12px;
}

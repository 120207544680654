@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.chart-block{
  border-radius: 16px;
  padding: 16px;
  margin: 8px 32px 32px;

  @media @viewport-mobile {
    margin: 0 !important;
    padding: 0 16px;
  }

  .with-theme({
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    color: @vars[neutral-800-to-200];

    @media @viewport-mobile {
      border: none;
    }
  });

  &.st-inner-chart {
    .chart-block_title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    .chart-block_sub-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.1px;
    }
  }

  .ad-spot-external-tooltip {
    padding: 16px;
    width: 243px;
    z-index: 1;
    border-radius: 12px;
    opacity: 1;
    position: absolute;
    transform: translate(-50%, 0);
    transition: all .3s ease;
    font-family: 'Inter', sans-serif;
    box-shadow: 0 0 16px 4px rgba(21, 30, 58, 0.10);
    pointer-events: initial !important;
    .with-theme({
      background: @vars[color-dark-to-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-btn {
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
      border: none;
      border-radius: @border-radius;
      cursor: pointer;
      background: @bluePrimary;
      color: @white;
      margin-top: 10px;

      &:hover {
        background: @blueDepressed;
      }
    }

    &-header {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
      color: @Neutral300;
    }

    &-subheader {
      margin-top: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: @Neutral50;
    }

    &-divider {
      width: 100%;
      height: 1px;
      margin: 16px auto;
      .with-theme({
        background: @vars[neutral-dark-300-to-neutral-300];
      })
    }

    &-subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
      color: @Neutral300;
    }

    .ad-spot-external-tooltip-status-wrapper {
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: default;
      text-transform: capitalize;
      gap: 4px;
      .with-theme({
        color: @Neutral50;
      });
    }

    .ad-spot-external-tooltip-status-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: @redPrimary;
      margin-right: 5px;

      &.active {
        background: @greenPrimary;
      }
    }

    &-top-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      .empty-message {
        margin-top: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: @Neutral50;
      }
    }

    &-top-item {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-top-item-image-wrapper {
      width: 24px;
      height: 24px;
      border-radius: @border-radius;
      margin-right: 4px;

      svg path {
        .with-theme({
          stroke: @Neutral200;
        });
      }

      &:not(.simple) {
        .with-theme({
          background: @vars[blue-100-to-900];
        });
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: @border-radius;
      }
    }

    &-top-item-title {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        color: @blueDepressed;
      }

      &.simple {
        cursor: default;
        text-transform: capitalize;
        color: @Neutral50;
      }
    }

    &-top-item-value {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-left: auto;
      color: @Neutral50;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;

    .period-filter{
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        width: 100%;
        justify-content: space-around;
        padding-bottom: 20px;
      }

    .period-filter-wrapper-icons {
      padding: 4px;
      height: 40px;
      gap: 8px;
      display: flex;
      justify-content: center;

      @media @viewport-mobile {
        width: 100%;
        margin-top: 12px;
      }

      &.mobile {
        min-width: 220px;
      }

      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 10px;
        background: @vars[neutral-50-to-neutral-dark-50];
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      .period-filter {
        padding: 4px;
        display: flex;
        justify-content: center;
        svg path {
          .with-theme({
            stroke: @vars[neutral-600-to-neutral-dark-500];
          });
        }

        &.active {
          background-color: white;
          border-radius: 8px;
          padding: 6px;
          color: @Neutral800;

          .with-theme({
            box-shadow: @vars[box-shadow-btn-secondary-without-border];
            background-color: @vars[white-to-neutral-dark-200];
            color: @vars[color-dark-to-white];
           });

           svg path {
            .with-theme({
              stroke: @vars[color-dark-to-white]
            });
           }
        }
      }
    }
    }

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      margin-bottom: 0;

      &:has(.actions-options) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0;
      }
    }

    .actions-options {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: @border-radius;
      transition: all .3s ease;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });

      &:hover {
        .with-theme({
          border: 1px solid @vars[neutral-400-to-blue-700] !important;
        });
      }

      &.ant-dropdown-open {
        .with-theme({
          border: 1px solid @vars[neutral-400-to-blue-700] !important;
        });
      }

      .icon-btn {
        transform: rotate(90deg);

        svg circle {
          .with-theme({
            fill: @vars[neutral-800-to-200];
          })
        }
      }

      &-dropdown {
        padding: 8px;
        border-radius: @border-radius;
        .with-theme({
          border: 1px solid @vars[neutral-100-to-800];
          background: @vars[color-white-to-dark];
        });

        .switch-option {
          padding: 10px 12px;
        }
      }
    }

    & > span.ads_quantity {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: @Neutral500;
      order: 3;
      margin-right: 12px;

      @media @viewport-mobile {
        display: none;
      }
    }
  }

  &_title{
    font-size: 16px;
    line-height: 24px;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;

    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  .switch-option {
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-switch {
      width: 32px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      .with-theme({
        background: @vars[neutral-200-to-700];
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @bluePrimary;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }

    p {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      .with-theme({
        color: @vars[color-dark-to-white];
      })
    }
  }

  &_sub-title{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &_link{
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;

    .icon{
      margin-left: 8px;
    }

    &:hover{
      text-decoration: underline;

      .icon{
        fill: @blueDepressed;
      }
    }
  }

  &_total-count{

    &-block {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      gap: 2px;

      @media @viewport-mobile {
        margin-bottom: 0;
      }
    }

    margin-left: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;

    &-wrap {
      display: flex;
      align-items: center;
      column-gap: 34px;
      row-gap: 12px;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media @viewport-mobile {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 11px;
      }
    }

    &-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      width: fit-content;

      &.returns {
        svg rect {
          fill: @orangePrimary;
        }
      }

      &.basic.raw {
        svg rect {
          fill: @blueDepressed;
        }
      }

      &.returns.raw {
        svg rect {
          fill: @orangeDepressed;
        }
      }
    }

    &-title-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-title {
      color: @Neutral600;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
